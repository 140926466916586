<template>
    <v-navigation-drawer v-if="userData"
                         v-model="drawer"
                         app
                         temporary
                         right
                         :width="drawer?320:0"
                         style="height: 100%; overflow: auto; z-index: 1021; box-sizing: border-box">
        <v-list class="pt-4 px-4 pb-1">
            <v-card color="primary"
                    dark
                    :elevation="isDesktop?24:0"
                    style="border-radius: 15px;">
                <v-list-item two-line dense>
                    <v-list-item-content>
                        <v-list-item-title><b>{{ userData.name }}</b></v-list-item-title>
                        <v-list-item-subtitle>
                            {{ userData.email }}
                        </v-list-item-subtitle>
                    </v-list-item-content>
                    <v-list-item-avatar>
                        <v-img :src="userData.avatar" :aspect-ratio="1"></v-img>
                    </v-list-item-avatar>
                </v-list-item>
            </v-card>
        </v-list>
        <v-list dense subheader v-if="useable_shop_point || my_lnwpoint_obj">
            <v-subheader style="margin: 5px 0 -5px;">{{ $t('คะแนนของฉัน') }}</v-subheader>
            <v-list-item v-if="useable_shop_point">
                <v-list-item-icon>
                    <v-icon class="white--text app-like-small blue-grey lighten-2" small>mdi-star-four-points-circle-outline</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                    <v-list-item-title>
                        {{ number_format(useable_shop_point) }} {{ $t('คะแนน') }}
                    </v-list-item-title>
                </v-list-item-content>
                <v-list-item-icon style="justify-content: flex-end">
                    <v-tooltip top>
                        <template v-slot:activator="{ on, attrs }">
                            <v-icon
                                color="primary"
                                dark
                                x-small
                                v-bind="attrs"
                                v-on="on"
                            >
                                mdi-information-outline
                            </v-icon>
                        </template>
                        <span>{{ $t('คะแนนสะสมที่ใช้ได้กับร้านค้า')}}</span>
                    </v-tooltip>
                </v-list-item-icon>
            </v-list-item>
            <v-list-item v-if="my_lnwpoint_obj">
                <v-list-item-icon>
                    <div class="white--text app-like-small blue-grey lighten-5"><img style="display: inline-block; width: 16px; height: 16px; margin: 0 auto;"
                                                                                     :src="require('@/assets/icon_lnwpoints.png')"/></div>
                </v-list-item-icon>
                <v-list-item-content>
                    <v-list-item-title>
                        {{ number_format(my_lnwpoint_obj.available_balance) }} {{ $t('คะแนน') }}
                    </v-list-item-title>
                </v-list-item-content>
                <v-list-item-icon style="justify-content: flex-end">
                    <v-tooltip top max-width="200">
                        <template v-slot:activator="{ on, attrs }">
                            <v-icon
                                color="primary"
                                dark
                                x-small
                                v-bind="attrs"
                                v-on="on"
                            >
                                mdi-information-outline
                            </v-icon>
                        </template>
                        <span>{{ $t('คะแนนสะสม LnwPoints สามารถใช้เป็นส่วนลดได้ทุกร้านค้าที่ใช้งาน LnwPay')}}</span>
                    </v-tooltip>
                </v-list-item-icon>
            </v-list-item>
        </v-list>
        <v-list v-if="shopData && !myOrderShopData" class="pt-4 px-4 pb-1 "
                subheader>
            <v-subheader style="margin: 5px 0 0px;">{{ $t('รายการสั่งซื้อของฉัน') }}</v-subheader>
            <v-list-item two-line
                         dense
                         link
                         @click="$router.push('/order/' + shopData.web_name)"
                         target="_blank"
                         class="text-decoration-none elevation-24 border">
                <v-list-item-action>
                    <v-img width="40"
                           :aspect-ratio="1"
                           class="rounded-lg"
                           :src="shopData.avatar"></v-img>
                </v-list-item-action>
                <v-list-item-content>
                    <v-list-item-title><b>{{ $t('รายการสั่งซื้อของฉัน') }}</b></v-list-item-title>
                    <v-list-item-subtitle style="font-size: 0.8em;">
                        {{ shopData.domain }}
                    </v-list-item-subtitle>
                </v-list-item-content>
                <v-list-item-action>
                    <v-icon color="primary"
                            small>
                        mdi-chevron-right
                    </v-icon>
                </v-list-item-action>
            </v-list-item>
        </v-list>
        <!--        <v-divider inset></v-divider>-->
        <v-list dense subheader>
            <v-subheader style="margin: 5px 0 -5px;">{{ $t('Lnw.me') }}</v-subheader>
            <v-list-item @click="1"  href="/app">
                <v-list-item-icon>
                    <div class="app-like-small blue-grey lighten-2">
                        <v-icon small dark>mdi-home</v-icon>
                    </div>
                </v-list-item-icon>
                <v-list-item-title>{{ $t('หน้าหลัก') }} Lnw.Me</v-list-item-title>
            </v-list-item>
            <v-list-group v-if="devel"
                          color="primary"
                          prepend-icon="mdi-shopping"
                          @change="showWaitingOrder = !showWaitingOrder">
                <template v-slot:activator>
                    <v-list-item-title>{{ $t('รายการสั่งซื้อ') }}
                        <div v-if="showWaitingOrder && hasWaitingOrder > 0"
                             class="d-inline-block">
                            <div class="redBadge">{{ hasWaitingOrder }}</div>
                        </div>
                    </v-list-item-title>
                </template>
                <div v-for="(order, i) in orderOK"
                     :key="i"> <!-- ตรงนี้ น่าจะต้องไปใช้ source จากแหล่งอื่น ที่ไม่ใช่ orderOK -->
                    <v-list-item dense
                                 link
                                 v-if="order.quantity > 0">
                        <v-list-item-action style="text-align: center">
                            <img style="width: 12px; height: 12px; margin-left: 18px"
                                 :src="require('@/assets/order_status/'+ order.status +'.png')"
                                 alt="">
                        </v-list-item-action>
                        <v-list-item-title>
                            {{ order.text }}
                        </v-list-item-title>
                        <v-list-item-icon>
                            <div v-if="order.quantity > 0"
                                 style="margin-top: 4px;"
                                 :style="(order.quantity>9)?'font-size: 0.6rem':''"
                                 :class="(order.status !== 'completed')?'redBadge':'lightBadge'">
                                {{ order.quantity }}
                            </div>
                        </v-list-item-icon>
                    </v-list-item>
                </div>
                <v-list-group no-action
                              sub-group>
                    <template v-slot:activator>
                        <v-list-item-content>
                            <v-list-item-subtitle class="pl-1">{{ $t('ยกเลิก') }} / {{ $t('คืนเงิน') }}</v-list-item-subtitle>
                        </v-list-item-content>
                    </template>
                    <v-list-item dense
                                 v-for="(order, i) in orderCancel"
                                 :key="i"
                                 link>
                        <v-list-item-action style="text-align: center">
                            <img style="width: 12px; height: 12px; margin: 0 6px;"
                                 :src="require('@/assets/order_status/'+ order.status +'.png')"
                                 alt="">
                        </v-list-item-action>
                        <v-list-item-title>
                            {{ order.text }}
                        </v-list-item-title>
                        <v-list-item-icon>
                            <div v-if="order.quantity > 0"
                                 style="margin: 7px auto 0"
                                 :style="(order.quantity>9)?'font-size: 0.75rem':''"
                                 class="minor">
                                {{ order.quantity }}
                            </div>
                        </v-list-item-icon>
                    </v-list-item>
                </v-list-group>
            </v-list-group>
            <v-list-group v-if="devel"
                          prepend-icon="mdi-bookmark-check"
                          @change="showWaitingReview = !showWaitingReview">
                <template v-slot:activator>
                    <v-list-item-title>{{ $t('รีวิวของฉัน') }}
                        <div v-if="showWaitingReview && reviews[0].quantity > 0"
                             class="d-inline-block">
                            <div class="redBadge">{{ reviews[0].quantity }}</div>
                        </div>
                    </v-list-item-title>
                </template>
                <v-list-item v-for="(review, i) in reviews"
                             :key="i"
                             link>
                    <v-list-item-action></v-list-item-action>
                    <v-list-item-title>
                        {{ review.text }}
                    </v-list-item-title>
                    <v-list-item-icon>
                        <div v-if="review.quantity > 0"
                             style="margin: 7px auto 0"
                             :style="(review.quantity>9)?'font-size: 0.6rem':''"
                             :class="(review.status !== 'reviewed')?'redBadge':'lightBadge'">
                            {{ review.quantity }}
                        </div>
                    </v-list-item-icon>
                </v-list-item>
            </v-list-group>
            <v-list-group value="true"
                          @change="showAccountSetting = !showAccountSetting">
                <template v-slot:activator>
                    <v-list-item-title>{{ $t('ข้อมูลของฉัน') }}</v-list-item-title>
                </template>
                <template v-slot:prependIcon>
                    <div class="app-like-small" :class="(showAccountSetting)?'primary':'blue-grey lighten-2'">
                        <v-icon small color="white">mdi-account</v-icon>
                    </div>
                </template>
                <v-list-item link
                             :href="accountUrl('manage/main/profile')">
                    <v-list-item-action></v-list-item-action>
                    <v-list-item-title>
                        {{ $t('เปลี่ยนข้อมูลบุคคล') }}
                    </v-list-item-title>
                    <v-list-item-icon>
                        <v-icon small>mdi-account-circle</v-icon>
                    </v-list-item-icon>
                </v-list-item>
                <v-list-item link
                             :href="accountUrl('manage/main/address')">
                    <v-list-item-action></v-list-item-action>
                    <v-list-item-title>
                        {{ $t('สมุดที่อยู่ของฉัน') }}
                    </v-list-item-title>
                    <v-list-item-icon>
                        <v-icon small>mdi-map-marker</v-icon>
                    </v-list-item-icon>
                </v-list-item>
                <v-list-item link
                             :href="accountUrl('manage/setting')">
                    <v-list-item-action></v-list-item-action>
                    <v-list-item-title>
                        {{ $t('LB-RESET-PSW') }}
                    </v-list-item-title>
                    <v-list-item-icon>
                        <v-icon small>mdi-account-key</v-icon>
                    </v-list-item-icon>
                </v-list-item>
                <v-list-item link
                             :href="accountUrl('manage/setting')">
                    <v-list-item-action></v-list-item-action>
                    <v-list-item-title>
                        {{ $t('ตั้งค่าบัญชี') }}
                    </v-list-item-title>
                    <v-list-item-icon>
                        <v-icon small>mdi-cog</v-icon>
                    </v-list-item-icon>
                </v-list-item>
            </v-list-group>
        </v-list>
        <!--        <v-divider inset></v-divider>-->
        <v-list dense>
            <v-list-item @click.prevent.stop="logout">
                <v-list-item-icon>
                    <div class="app-like-small blue-grey lighten-2">
                        <v-icon small dark>mdi-logout</v-icon>
                    </div>
                </v-list-item-icon>
                <v-list-item-title>{{ $t('ออกจากระบบ') }}</v-list-item-title>
            </v-list-item>
        </v-list>
        <v-list dense class="pb-2">
            <v-subheader style="margin: 5px 0 -5px;">{{ $t('การแสดงผล') }}</v-subheader>
            <v-list-item class="px-2 rounded-0">
                <template>
                    <v-btn-toggle v-model="selectedTheme"
                                  style="width: 100%;"
                                  color="primary">
                        <v-btn v-for="(option, index) in themeOptions"
                               :value="option.mode"
                               :key="index"
                               style="flex: 1 0 33.33%;"
                               class="py-4"
                               @click="setTheme(option.mode)">
                            <v-icon :color="isDark?'white':'primary'" class="mr-1" small>{{ option.icon }}</v-icon>
                            <span style="font-size: 0.7em;">{{ option.label }}</span>
                        </v-btn>
                    </v-btn-toggle>
                </template>
            </v-list-item>
        </v-list>
        <template v-slot:append>
            <v-list class="px-5">
                <v-btn block
                       large
                       depressed
                       @click="drawer = false"
                       class="mt-2 mb-4">
                    {{ $t('ปิดหน้าต่าง') }}
                </v-btn>
            </v-list>
        </template>
    </v-navigation-drawer>
</template>
<script>
import {mapGetters, mapActions, mapState} from "vuex";
import Helper from "@/libraries/Helper.js";
import SiteUrl from "@/libraries/SiteUrl.js";
import MixinsHelper from "@/components/Order/mixins/MixinsHelper";

export default {
    name: "TheUserDrawer",
    props: ['value'],
    data(){
        return {
            devel: false,
            orderOK: [
                {
                    status: 'wait_order',
                    text: this.$t('รอยืนยันรายการ'),
                    quantity: 2,
                    banner: null,
                    desc: ''
                },
                {
                    status: 'wait_order_confirm',
                    text: this.$t('รอร้านยืนยันรายการ'),
                    quantity: 1,
                    banner: 'กรุณาชำระเงินภายใน <span>12:00:00</span>',
                    desc: 'กรุณาชำระเงินพร้อมแจ้งชำระเงินก่อนใบสั่งซื้อนี้จะหมดอายุในวันที่ 12 สิงหาคม เวลา 13:12 น.',
                    color: 'yellow darken-3'
                },
                {
                    status: 'wait_payment',
                    text: this.$t('รอชำระเงิน'),
                    quantity: 1,
                    banner: 'กรุณาชำระเงินภายใน <span>12:00:00</span>',
                    desc: 'กรุณาชำระเงินพร้อมแจ้งชำระเงินก่อนใบสั่งซื้อนี้จะหมดอายุในวันที่ 12 สิงหาคม เวลา 13:12 น.',
                    color: 'yellow darken-3'
                },
                {
                    status: 'wait_payment_verify',
                    text: this.$t('รอร้านยืนยันยอด'),
                    quantity: 0,
                    banner: 'รอร้านค้าตรวจสอบยอดเงิน',
                    desc: 'ร้านค้าได้รับสลิปการแจ้งชำระเงินจากคุณแล้ว และกำลังตรวจสอบยอดเงินที่แจ้งกับยอดเงินในบัญชีเพื่อยืนยันการสั่งซื้อของคุณ',
                    color: 'yellow darken-3'
                },
                {
                    status: 'wait_send',
                    text: this.$t('รอจัดส่ง'),
                    quantity: 1,
                    banner: 'รอร้านค้าแพ็คและจัดส่งสินค้า',
                    desc: 'ร้านค้ายืนยันการชำระเงินเรียบร้อยแล้ว และกำลังแพ็คสินค้าเพื่อจัดส่งไปยังขนส่งที่คุณเลือก',
                    color: 'yellow darken-3'
                },
                {
                    status: 'wait_receive',
                    text: this.$t('รอรับสินค้า'),
                    quantity: 0,
                    banner: 'จัดส่งแล้ว กรุณายืนยันหากได้รับสินค้าแล้ว',
                    desc: 'ร้านค้าได้จัดส่งพัสดุแล้ว กรุณารอรับสินค้าจากผู้ให้บริการขนส่ง คุณสามารถตรวจสอบเลขรหัสพัสดุ',
                    color: 'green accent-4'
                },
                {
                    status: 'wait_confirm',
                    text: this.$t('รอยืนยันรับสินค้า'),
                    quantity: 0,
                    banner: 'ถึงผู้รับแล้ว กรุณายืนยันหากได้รับสินค้าแล้ว',
                    desc: 'ระบบตรวจสอบจากรหัสพัสดุ พบว่าพัสดุได้ส่งถึงมือผู้รับแล้ว กรุณายืนยันหากคุณได้รับสินค้าแล้ว หรือหากมีปัญหาในการรับพัสดุกรุณาแจ้งข้อร้องเรียน เพื่อให้ทีมงานตรวจสอบอีกครั้ง',
                    color: 'light-blue accent-3'
                },
                {
                    status: 'completed',
                    text: this.$t('เรียบร้อย'),
                    quantity: 12,
                    banner: 'การสั่งซื้อเสร็จเรียบร้อยแล้ว Thank you !',
                    desc: '',
                    color: 'light-blue accent-3'
                },
            ],
            orderCancel: [
                {status: 'refund', text: 'คืนเงิน', quantity: 1},
                {status: 'expired', text: 'หมดอายุ', quantity: 5},
                {status: 'seller_cancelled', text: 'ยกเลิกโดยร้าน', quantity: 0},
                {status: 'buyer_cancelled', text: 'ยกเลิก', quantity: 2},
            ],
            reviews: [
                {status: 'wait_review', text: 'รอรีวิว', quantity: 2},
                {status: 'reviewed', text: 'รีวิวแล้ว', quantity: 28},
            ],
            showWaitingOrder: false,
            showWaitingReview: false,
            showAccountSetting: true,
            themeOptions: [
                {label: 'Light', icon: 'mdi-weather-sunny', mode: 'light'},
                {label: 'Dark', icon: 'mdi-weather-night', mode: 'dark'},
                {label: 'Auto', icon: 'mdi-creation', mode: 'system'},
            ],
        };
    },
    mixins: [MixinsHelper],
    computed: {
        ...mapGetters({
            canUnsetUser: 'order/canUnsetUser',
        }),
        ...mapState({
            userData: state => state.user.userData,
            shopData: state => state.order.shopData,
            myOrderShopData : state => state.myOrders.shopData,
            shop_point_from_orderData: state => state.order.orderData?.useable_shop_point,
            shop_point_from_orderList: state => state.myOrders?.userDataInShop?.point_data?.available_balance,
            my_lnwpoint_obj: state => {
                return state.lnwpay.point
                    ? state.lnwpay.point
                    : {
                        available_balance: null,
                        available_money: null,
                        point_value: null,
                    };
            },
        }),
        useable_shop_point() {
            return this.shop_point_from_orderData || this.shop_point_from_orderList;
        },
        drawer: {
            get(){
                return this.value;
            },
            set(newValue){
                this.$emit('input', newValue);
            },
        },
        hasWaitingOrder(){
            // return 0;
            // ต้องอ่านจาก source อื่น ที่ไม่ใช่ orderOK

            let totalQuantity = this.orderOK.reduce(function (prev, cur){
                if(cur.status == 'completed'){
                    return prev;
                }
                return prev + parseFloat(cur.quantity);
            }, 0);
            // console.log(totalQuantity);
            return totalQuantity;
        },
        themeColor(){
            return this.$vuetify.theme.dark
                ? this.$vuetify.theme.themes.dark.themeColor
                : this.$vuetify.theme.themes.light.themeColor;
        },
    },
    methods: {
        ...mapActions({
            'actionUserLogout': 'user/logout',
            actionUnclaimedOrder: 'order/unclaimedOrder',
        }),
        number_format(num, decimal){
            return Helper.number_format(num, decimal);
        },
        async logout(){
            if(this.canUnsetUser){
                await this.actionUnclaimedOrder();
            }
            await this.actionUserLogout();
            this.drawer = false;
        },
        accountUrl(uri){
            return SiteUrl.account(uri);
        },

        setTheme(theme){
            this.menuTheme = false;

            if(theme === 'light'){
                this.selectedTheme = 'light';
                this.$vuetify.theme.dark = false;
            }else if(theme === 'dark'){
                this.selectedTheme = 'dark';
                this.$vuetify.theme.dark = true;
            }else if(theme === 'system'){
                this.selectedTheme = 'system';
                this.checkSystemTheme();
                window.matchMedia('(prefers-color-scheme: dark)').addEventListener('change', this.checkSystemTheme);
            }
            document.cookie = `_lnwme_appearance=${theme}; path=/; max-age=31536000`;

            this.$nextTick(() => this.setMetaTag())

        },
        checkSystemTheme(){
            const prefersDark = window.matchMedia('(prefers-color-scheme: dark)').matches;
            this.$vuetify.theme.dark = prefersDark;
        },
    },
    mounted(){
        const theme = this.getCookie('_lnwme_appearance');
        if(!theme){
            this.checkSystemTheme();
        }else {
            this.setTheme(theme);
        }

    }
}
</script>
<style scoped>
.theme--dark.v-btn::before {
  transition: none;
}
.theme--dark.v-btn--active span {
  color : white;
}
.theme--dark.v-btn--active:hover::before, .theme--dark.v-btn--active::before {
  opacity: 1;
}
</style>